<template>
  <!-- invoices START -->
  <div class="account-invoices" v-if="invoices">
    <!-- invoice:filter START -->
    <div class="filter-holder">
      <div class="length">
        <span
          v-if="filteredInvoices.length === 1"
          v-html="`${filteredInvoices.length} ${$t('account.invoice.invoice')}`"
        ></span>
        <span
          v-else
          v-html="`${filteredInvoices.length} ${$t('account.invoice.invoices')}`"
        ></span>
      </div>
      <div
        class="trigger"
        v-on:click="triggerFilter"
      >
        <span v-html="$t('account.invoice.filter')"></span>
      </div>
    </div>

    <invoice-filter
      :triggerFilterData="triggerFilterData"
      :activeFilter="filterActive"
      :triggerFilter="triggerFilter"
      :dateTimePicker="dateTimePicker"
    />
    <!-- invoice:filter END -->

    <!-- invoice:invoices START -->
    <div class="invoices">
      <invoice
        v-for="(invoice, invoiceIndex) in filteredInvoices"
        :key="`invoices-invoice-${invoiceIndex}`"
        :invoice="invoice"
      />
    </div>
    <!-- invoice:invoices END -->
  </div>
  <!-- invoices END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'invoices',
  mixins: [titleMixin],
  components: {
    Invoice: () => import('../../components/account/Invoice.vue'),
    InvoiceFilter: () => import('../../components/account/InvoiceFilter.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.invoice.headline'),

      filterActive: false,
      dateTimePicker: [],
      filterData: '',
      filterDataEnd: '',

      invoices: null,
    };
  },
  async created() {
    this.invoices = await this.FETCH_ORDERS();
  },
  computed: {
    filteredInvoices() {
      if (this.filterData && this.filterDataEnd === '') {
        return this.invoices
          .filter((invoice) => this.$date(invoice.orderDate) >= this.$date(this.filterData));
      }

      if (this.filterData && this.filterDataEnd) {
        return this.invoices
          .filter((invoice) => this.$date(invoice.orderDate) >= this.$date(this.filterData)
            && this.$date(invoice.orderDate) <= this.$date(this.filterDataEnd));
      }

      return this.invoices;
    },
  },
  methods: {
    async FETCH_ORDERS() {
      await this.$store.dispatch('getOrders');

      // sort invoices
      this.$store.getters.orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));

      return this.$store.getters.orders;
    },

    triggerFilter() {
      this.filterActive = !this.filterActive;
    },

    triggerFilterData(data) {
      if (data.length === 2) {
        this.dateTimePicker = data;
        this.filterData = this.$date(this.dateTimePicker[0]).format();
        this.filterDataEnd = this.$date(this.dateTimePicker[1]).format();
      } else {
        this.filterData = data;
        this.filterDataEnd = '';
      }
      setTimeout(() => {
        this.filterActive = false;
      }, 350);
    },
  },
};
</script>
